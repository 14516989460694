.block {
  position: relative;
  left: -1.25rem;
  width: calc(100% + 2.5rem);
  padding-left: calc(0.75rem - 2px);
  padding-right: 1.25rem;

  margin-top: 1rem;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  border-radius: 1px;
  border-width: 0.75rem calc(0.5rem + 2px);
  border-left-width: 0px;
  border-style: solid;
  border-color: transparent;
  border-right-color: #F3F1FD;
}

.title {
  color: #795DE7;
}

.innerBlock {
  flex-grow: 1;
  border-radius: 8px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: #F3F1FD;
}
