.card {
  width: 22rem;
  padding: 1.5rem !important;
  background: #FFFFFF;

  box-shadow: 0rem 1rem 1rem rgba(64, 48, 141, 0.16) !important;
  border-radius: 1rem !important;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.cancelButton {
  flex-shrink: 0;
  width: 9rem;
  height: 2.5rem;
  font-size: 0.875rem;
}

.body {
  line-height: 1.5rem;
}

.header {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #EFEFEF;
}

.title {
  font-size: 1.25rem;
  font-weight: 900;
}

.closeButton {
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;
}

.deleteButton {
  flex-shrink: 0;
  width: 9rem;
  height: 2.5rem;
  font-size: 0.875rem;
  border-width: 1px;
}
