.description {
  resize: none;
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.input:invalid {
  box-shadow: none !important;
}

.input::placeholder {
  font-size: 1rem;
  color: #C6C6C6;
}
