.auth {
  width: 100vw;
  height: 100vh;
}

.branding {
  background-color: #311C69;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 20px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.input::placeholder {
  font-size: 1rem;
  color: #C6C6C6;
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.forgotPassword, .forgotPassword:hover, .forgotPassword:focus {
  color: #795DE7;
}

.submitButton {
  flex-shrink: 0;
  font-size: 1.125rem;
}

.formSection {
  background-color: #FFF;
  display: flex;
}

.formPadding {
  display: flex;
  justify-content: center;
  width: 100%;

  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formContainer > * {
  align-self: flex-start;
}

.form {
  width: 100%;
}

@media (min-width: 768px) {
  .formPadding.large {
    padding-left: 16%;
    padding-right: 20%;
    justify-content: left;
  }
}

@media (min-width: 992px) {
  .formPadding.large {
    padding-left: 20%;
    padding-right: 25%;
  }
}
