.dateInput {
  border: 1px solid #E5E5E5 !important;
  border-radius: 8px;
  font-size: 1rem;
  background: transparent;
}

.pickerRoot {
  height: 100%;
  padding: 0.75rem 1rem;
  background: transparent;
}

.pickerRoot::before {
  content: none !important;
}

.pickerRoot::after {
  content: none !important;
}

.pickerInput {
  background: transparent;
}

.pickerInput::placeholder {
  color: #C6C6C6 !important;
}

.placeholder {
  position: absolute;
  padding: 7px 1rem;
  color: #C6C6C6 !important;
}
