.resetPassword {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.resetPassword > * {
  align-self: flex-start;
}

.title {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.form {
  width: 100%;
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 20px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.input::placeholder {
  font-size: 1rem;
  color: #C6C6C6;
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.signin, .signin:hover, .signin:focus {
  color: #795DE7;
}

.submitButton {
  flex-shrink: 0;
  font-size: 1.125rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
