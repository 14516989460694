.dropZone {
  display: flex;
  flex-direction: row;

  width: calc(100% + 36px);
  left: -18px;
  height: 100%;

  position: absolute;
  background: none;
  border: none;
}

.notDragging {
  z-index: -1;
}

.dragging {
  z-index: 999;
}
