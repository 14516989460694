.dragIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.poster {
  height: 16.2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: cover;
}

.hide {
  visibility: hidden;
}
