.dragIcon {
  height: 32px;
  width: 32px;
  background-color: #FFF;
  border-radius: 50%;
  display: inline-block;

  display: flex;
  justify-content: center;

  align-items: center;
}