.card {
  width: 16rem;
  background: #FFFFFF;

  box-shadow: 0rem 1rem 1rem rgba(64, 48, 141, 0.16);
  border-radius: 1rem;
}

.poster {
  height: 16.2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: cover;
}

.bullet {
  font-size: 0.7rem;
}

.genres {
  letter-spacing: 0.1rem;
  font-size: 0.65rem;
}

.title {
  font-size: 1rem;
  font-weight: 800;
}

.footer {
  background-color: unset;
  border-top: none;
}
