.dialog {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #F3F1FD;
  height: 35rem;
  width: 30rem;
  border-radius: 16px;
}

.title {
  padding: 1rem 1rem;
  font-size: 2rem;
  font-weight: 900;
}

.vodProviderList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0rem 2rem;
}

.vodProviderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vodProviderName {
  font-size: 1.2rem;
}

.controls {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saveStatus {
  margin-right: 1rem;
  color: #795DE7;
}

.spinner {
  display: flex;
  justify-content: center;
  color: #795DE7;
}