.activeTab {
  font-size: 1.3rem;
  font-weight: 900;
  color: #000;
  border-bottom: 2px solid #000;
  border-top: 2px solid transparent;
}

.inactiveTab {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 900;
  color: rgba(49, 28, 105, 0.2);
  border-bottom: 2px solid rgba(49, 28, 105, 0.2);
  border-top: 2px solid transparent;
}

.inactiveTab a {
  text-decoration: none;
  color: unset;
}

.searching {
  position: fixed;
  top: 50vh;
  left: calc(50vw - 80px);
}

.saving {
  color: #795DE7;
}
