.block {

}

.highlighted {
  background: #F3F1FD;
  transition: background-color 300ms linear;
}

.highlighted .date {
  background-color: #FFF !important;
  transition: background-color 300ms linear !important;
}

.unhighlighted {
  background: #FFF;
  transition: background-color 300ms linear;
}

.innerBlock {
  padding-top: 1rem;
  padding-bottom: 1.25rem;

  position: relative;
  left: -1.25rem;
  width: calc(100% + 2.5rem);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 800;
  background-color: #EFEFEF;
  border-radius: 8px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  min-height: 3.25rem;
  max-width: 8.5rem;

  transition: background-color 600ms linear;
}

.date.empty {
  background-color: rgba(239, 239, 239, 0.4);
}

.arrow {
  margin: 0px 10px;
}

.arrow.empty {
  opacity: 0.5;
}

.editButton {
  min-height: 3.25rem;
  width: 6.25rem;
}

.editButton.empty {
  border-radius: 8px;
  background-color: #F3F1FD;
  min-width: 6.5rem;
}

.divider {
  width: 32px;
}

.disabled .innerBlock {
  opacity: 0.35;
}
