.button {
  border: 1.5px solid #000000;
  border-radius: 1.25rem;

  font-weight: 800;
  font-size: 14px;
}

.button:disabled {
  border-color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}
