.header {
  background-color: #FFF;
  box-shadow: 0rem 0.25rem 0.5rem rgba(64, 48, 141, 0.08);
}

.activeLink {
  font-size: 1rem;
  font-weight: 800;
  color: #000;
}

.link {
  font-size: 1rem;
  font-weight: 800;
  color: #795DE7;
}

.optionButton {
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;
}

.logoutButton {
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;

  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  color: #795DE7;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);
  background-color: #FFF;
}

.logoutButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
