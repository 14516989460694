.leftDropZone {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.gutter {
  width: 18px;
  border-radius: 1rem;
}

.body {
  flex-grow: 1;
}

.highlighted {
  background-color: #795DE7;
}
